import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import { API_URL, MI_API_URL } from '../config/globals';
import { NEXT_CUSTOMER_LOADED, CUSTOMER_LOADED, FACILITIES_LOADED, FOLLOWUPS_LOADED, FOLLOWUP_METADATA_LOADED, searchTypes } from './config';
import { getNow } from '../utils/common';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Content-Type'] = "application/json";

export const searchCustomer = (search, searchBy) => {
    if (!search) {
        return;
    }

    return async (dispatch, getState) => {
        if (searchBy === searchTypes.PHONE_NUMBER) {
            axios({
                method: 'get',
                url: API_URL + '/AccessManager/getLeadbyPhoneNumber',
                params: {
                
                    phonenumber: search
                }
            }).then((results) => {
                dispatch({
                    type: CUSTOMER_LOADED,
                    payload: results.data
                });
            }).catch(function (error) {
                toastr.error('Error', error.message);
            });
        } else {
            axios({
                method: 'get',
                url: API_URL + '/AccessManager/getLeadbyCustomerID',
                params: {
                
                    customer_id: search
                }
            }).then((results) => {
                dispatch({
                    type: CUSTOMER_LOADED,
                    payload: results.data
                });
            }).catch(function (error) {
                toastr.error('Error', error.message);
            });
        }
    }
}


export const autoDialCustomer = () => {
    return async (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/getHandingLeadInfo',
            params: {
            
                agent_id: getState().user.user_details.extension
            }
        }).then((results) => {
            dispatch({
                type: CUSTOMER_LOADED,
                payload: results.data
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });

    }
}

export const getNextFacility = (next) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/GetNextCustomertoDial',
            params: {
            
                user_id: getState().user.login_id
            }
        }).then(function (results) {
            if (results.data.customer_id) {
                dispatch({
                    type: NEXT_CUSTOMER_LOADED,
                    payload: results.data
                });
                next(results.data.customer_id);
            } else {
                dispatch({
                    type: CUSTOMER_LOADED,
                    payload: null
                });
                toastr.warning('No Data Found', "No facility detailes returned for calling");
            }
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadFacilities = (campaign_id = "") => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DIALER/GetLeadsList.htm',
            params: {
            
                campaign_id: campaign_id
            }
        }).then(function (results) {
            dispatch({
                type: FACILITIES_LOADED,
                payload: results.data
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const followUp = (team, data, next) => {
    let path = '/DialerCore/setFollowupsReminders';

    if (team) {
        path = '/DialerCore/setFollowupsTeamReminders';
    }
    
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + path,
            params: {
                
            },
            data: {
                ...data,
                extension: getState().user.user_details.extension
            }
        }).then(function (results) {
            toastr.success('Saved', "Follow up note saved successfully.");
            dispatch(loadFollowUps());
            next();
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const call = (data, next) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/Dial',
            params: {
            
                customer_id: data.customer_id,
                number: data.number,
                extension: getState().user.user_details.extension
            }
        }).then(function (results) {
            next();
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}


export const callDirect = (data, next) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/Dialdirect',
            params: {
            
                customer_id: data.customer_id,
                number: data.number,
                extension: getState().user.user_details.extension
            }
        }).then(function (results) {
            next();
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadFollowUps = (data) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/GetFollowupCustomerList',
            params: {
            
                user_id: getState().user.login_id
            },
            data
        }).then(function (results) {
            dispatch({
                type: FOLLOWUPS_LOADED,
                payload: results.data.followupList
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const loadFollowUpMetaData = (data) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: MI_API_URL + '/getNoteCodeList',
            params: {
                
            },
            data
        }).then(function (results) {
            dispatch({
                type: FOLLOWUP_METADATA_LOADED,
                payload: results.data.notesMasterDataVM
            });
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const deleteFollowUps = (customer_id) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DialerCore/clearFollowupReminder',
            params: {
            
                customer_id
            }
        }).then(function (response) {
            if (response.data) {
                dispatch(loadFollowUps());
            } else {
                toastr.error('Error', 'Failed to delete the follow up detail');
            }
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}


export const deleteFacility = (lead_id, campaign_id = "") => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DIALER/deleteLeadByID',
            params: {
            
                lead_id
            }
        }).then(function (response) {
            if (response.data) {
                dispatch(loadFacilities(campaign_id));
            } else {
                toastr.error('Error', 'Failed to delete the facility detail');
            }
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const addNote = (data, next) => {
    return (dispatch, getState) => {

        data.strNote = `${data.strNote} - Added by ${getState().user.user_details.first_name.trim()} ${getState().user.user_details.last_name.trim()} on ${getNow()}`;

        axios({
            method: 'post',
            url: MI_API_URL + '/AddNoteToContract',
            params: {
                
            },
            data
        }).then(function () {
            toastr.success('Saved', "Note saved successfully.");
            dispatch(loadNotes(data.contract_number));
            next();
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const searchByVehicle = (VehicleNo, next) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: MI_API_URL + '/getNICListByVehicleNumber',
            params: {
            
                VehicleNo
            },
        }).then(function (results) {
            next(results.data.Detail);
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}

export const changeFollowupOwnership = (data, next) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/DialerCore/changeFollowupOwnership',
            params: {
                
            },
            data: {
                ...data,
                owner_user_id: getState().user.login_id
            }
        }).then(function (results) {
            toastr.success('Success', "Follow-Ups are transfered successfully");
            next();
            dispatch(loadFollowUps());
        }).catch(function (error) {
            next(error);
            toastr.error('Error', error.message);
        });
    }
}