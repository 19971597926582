import { CSRF_TOKEN_UPDATED } from '../actions/config';

export default function (state = "", action) {
    switch (action.type) {
        case CSRF_TOKEN_UPDATED:
            return action.payload;
        default:
            return state;
    }
}
