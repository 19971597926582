import React, { Component } from 'react';
import { CardBody, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import history from '../../../history';
import { createUser, updateUser, setMobileSettings, setOutboundID } from '../../../actions/users';
import { listExtensions } from '../../../actions/configurations';
import { LOCAL_PHONE_REGEX, LOCAL_USER_MANAGEMENT } from '../../../config/globals';
import { logout } from '../../../actions';

class CreateUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login_id: "",
            first_name: "",
            last_name: "",
            phone_office: "",
            outbound_cli: "",
            role: "",
            login_username: "",
            extension: ""
        };
    }

    componentWillMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user.login_id !== this.props.user.login_id) {
            this.init();
        }
    }

    init() {
        const { roles, own, user } = this.props;

        if (user.login_id) {
            this.setState({
                ...user,
                phone_office: user.phone_office,
                role: _.find(roles, { role_id: user.login_role_id })
            })
        } else {
            this.setState({
                login_id: "",
                first_name: "",
                last_name: "",
                phone_office: "",
                outbound_cli: "",
                role: "",
                login_username: "",
                extension: ""
            });
        }
    }

    clearForm() {
        this.props.onClose();
    }

    saveData(e) {
        e.preventDefault();

        const self = this;

        if (LOCAL_PHONE_REGEX.test(this.state.phone_office)) {
            const data = {
                ...this.state,
                role: this.state.role ? this.state.role.role_id : this.state.login_role_id
            }
            if (this.state.login_id) {
                this.props.updateUser(data, (err) => {
                    if (!err) {
                        self.clearForm();
                    }
                });
            } else {
                this.props.createUser(data, (err) => {
                    if (!err) {
                        self.clearForm();
                    }
                });
            }
        } else {
            alert("Invalid Mobile Number.")
        }
    }

    changeMobile(e) {
        this.setState({ phone_office: e.target.value });
    }

    render() {

        const { roles } = this.props;

        return (
            <form onSubmit={(e) => this.saveData(e)} >
                <CardBody>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="first_name">First Name</Label>
                                <Input required type="text" value={this.state.first_name} onChange={(e) => this.setState({ first_name: e.target.value })} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="last_name">Last Name</Label>
                                <Input required type="text" value={this.state.last_name} onChange={(e) => this.setState({ last_name: e.target.value })} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="first_name">Mobile Number</Label>
                                <Input pattern="[0-9]{8,15}" required type="text" onChange={(e) => this.changeMobile(e)} value={this.state.phone_office} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="login_username">Username</Label>
                                <Input required readOnly={this.state.login_id} type="text" value={this.state.login_username} onChange={(e) => this.setState({ login_username: e.target.value })} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="login_username">Password</Label>
                                <Input required={!this.state.login_id} type="password" value={this.state.login_password} onChange={(e) => this.setState({ login_password: e.target.value })} />
                            </FormGroup>
                        </Col>
                    </Row>
                    {this.props.own ? "" : <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label htmlFor="role">Role</Label>
                                <Select
                                    required
                                    name="form-field-name2"
                                    value={this.state.role}
                                    options={roles}
                                    onChange={(e) => this.setState({ role: e })}
                                    getOptionValue={option => option['role_id']}
                                    getOptionLabel={option => option['name']}
                                />
                            </FormGroup>
                        </Col>
                    </Row>}
                    <Row>
                        <Col className="text-right">
                            <Button type="submit" color="primary">Save</Button>{' '}
                            <Button onClick={() => this.clearForm()} color="danger">Cancel</Button>
                        </Col>
                    </Row>
                </CardBody>
            </form>
        );
    }
}

function mapStateToProps({ roles, groups, outbound_ids }) {
    return {
        roles, groups, outbound_ids
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        listExtensions,
        createUser,
        logout,
        updateUser,
        setMobileSettings,
        setOutboundID
    }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(CreateUser));
