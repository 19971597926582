import React from 'react';
import Loadable from 'react-loadable';

// import Home from '../containers/Home';
import Loader from '../components/Loader';

function Loading() {
  return <Loader />;
}

const Dashboard = Loadable({
  loader: () => import('../containers/views/Dashboard/index'),
  loading: Loading,
});

const Campaigns = Loadable({
  loader: () => import('../containers/views/Campaigns/index'),
  loading: Loading,
});

const NewCampaign = Loadable({
  loader: () => import('../containers/views/Campaigns/create'),
  loading: Loading,
});

const Facilities = Loadable({
  loader: () => import('../containers/views/Facilities/index'),
  loading: Loading,
});

const DialFacility = Loadable({
  loader: () => import('../containers/views/Facilities/view'),
  loading: Loading,
});

const FollowUps = Loadable({
  loader: () => import('../containers/views/FollowUps/index'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('../containers/views/Users/index'),
  loading: Loading,
});

const Roles = Loadable({
  loader: () => import('../containers/views/Roles/index'),
  loading: Loading,
});

const Reports = Loadable({
  loader: () => import('../containers/views/Reports/KPIReports'),
  loading: Loading,
});

const Extensions = Loadable({
  loader: () => import('../containers/views/Configurations/Extentions/index'),
  loading: Loading,
});

const AgentConfigs = Loadable({
  loader: () => import('../containers/views/Configurations/AgentConfigs/index'),
  loading: Loading,
});

const QueueConfigs = Loadable({
  loader: () => import('../containers/views/Configurations/QueueConfigs/index'),
  loading: Loading,
});

const GroupConfigs = Loadable({
  loader: () => import('../containers/views/Configurations/GroupConfigs/index'),
  loading: Loading,
});

const Groups = Loadable({
  loader: () => import('../containers/views/Groups/index'),
  loading: Loading,
});

const Templates = Loadable({
  loader: () => import('../containers/views/Templates/index'),
  loading: Loading,
});

const DialerReports = Loadable({
  loader: () => import('../containers/views/Reports/DialerReports'),
  loading: Loading,
});

const DispositionReports = Loadable({
  loader: () => import('../containers/views/Reports/DispositionReports'),
  loading: Loading,
});

const KPIAnalyzer = Loadable({
  loader: () => import('../containers/views/Reports/KPIReports'),
  loading: Loading,
});

const AgentPerformance = Loadable({
  loader: () => import('../containers/views/Reports/AgentPerformance'),
  loading: Loading,
});

const AgentActivity = Loadable({
  loader: () => import('../containers/views/Reports/AgentActivity'),
  loading: Loading,
});

const QueueStatistics = Loadable({
  loader: () => import('../containers/views/Reports/QueueStatistics'),
  loading: Loading,
});

const CallDetails = Loadable({
  loader: () => import('../containers/views/Reports/CallDetails'),
  loading: Loading,
});

const AgentEtiquettes = Loadable({
  loader: () => import('../containers/views/Reports/AgentEtiquettes'),
  loading: Loading,
});


const CustomerFeedback = Loadable({
  loader: () => import('../containers/views/Reports/CustomerFeedback'),
  loading: Loading,
});

const AgentMonitor = Loadable({
  loader: () => import('../containers/views/Reports/ActivityMonitor'),
  loading: Loading,
});

const ActivityReport = Loadable({
  loader: () => import('../containers/views/Reports/ActivityReport'),
  loading: Loading,
});

const CallLogs = Loadable({
  loader: () => import('../containers/views/Reports/CallLogs'),
  loading: Loading,
});

const LocationTracker = Loadable({
  loader: () => import('../modules/location-tracker/views/tracker'),
  loading: Loading,
});

const LocationHistory = Loadable({
  loader: () => import('../modules/location-tracker/views/location-history'),
  loading: Loading,
});

const MessageHistory = Loadable({
  loader: () => import('../modules/location-tracker/views/message-history'),
  loading: Loading,
});

const CallStats = Loadable({
  loader: () => import('../modules/dashboards/views/index'),
  loading: Loading,
});

const CallStatsDashboard = Loadable({
  loader: () => import('../modules/dashboards/views/dashboard'),
  loading: Loading,
});

const AgentBreakConfig = Loadable({
  loader: () => import('../containers/views/Configurations/AgentBreak'),
  loading: Loading,
});

const FieldLayout = Loadable({
  loader: () => import('../modules/field-layout/views/index'),
  loading: Loading,
});

const UploadLeads = Loadable({
  loader: () => import('../modules/leads/views/upload'),
  loading: Loading,
});

const SMSTemplates = Loadable({
  loader: () => import('../modules/sms-templates/views/index'),
  loading: Loading,
});

const EmailTemplates = Loadable({
  loader: () => import('../modules/email-templates/views/index'),
  loading: Loading,
});

const FollowUpTemplates = Loadable({
  loader: () => import('../modules/followup-note-templates/views/index'),
  loading: Loading,
});

const DispositionTemplates = Loadable({
  loader: () => import('../modules/disposition-note-templates/views/index'),
  loading: Loading,
});

const Disposition = Loadable({
  loader: () => import('../modules/disposition/views/index'),
  loading: Loading,
});

const DispositionClasses = Loadable({
  loader: () => import('../modules/disposition-classes/views/index'),
  loading: Loading,
});

const Dispositions = Loadable({
  loader: () => import('../modules/dispositions/views/index'),
  loading: Loading,
});

const DispositionPlans = Loadable({
  loader: () => import('../modules/disposition-plans/views/index'),
  loading: Loading,
});

const CallCounts = Loadable({
  loader: () => import('../containers/views/Reports/CallCounts'),
  loading: Loading,
});

const Crm = Loadable({
  loader: () => import('../modules/crm/views/index'),
  loading: Loading,
});

const CrmFieldSettings = Loadable({
  loader: () => import('../modules/crm/views/field-settings'),
  loading: Loading,
});

const ContactView = Loadable({
  loader: () => import('../modules/crm/views/view'),
  loading: Loading,
});

const SalesOrders = Loadable({
  loader: () => import('../modules/orders/views/index'),
  loading: Loading,
});

const MessengerConfigurations = Loadable({
  loader: () => import('../modules/messenger/views/configurations'),
  loading: Loading,
});

const Inbox = Loadable({
  loader: () => import('../modules/messenger/views/inbox'),
  loading: Loading,
});

const ServiceConfigurations = Loadable({
  loader: () => import('../modules/service-configurations/views/index'),
  loading: Loading,
});

const DncManagement = Loadable({
  loader: () => import('../modules/dnc/views/index'),
  loading: Loading,
});


const routes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, exact: true, isAuth: true },
  { path: '/campaigns', name: 'Campaigns', component: Campaigns, exact: true, isAuth: true },
  { path: '/campaigns/new', name: 'New Campaign', component: NewCampaign, isAuth: true },
  { path: '/leads', name: 'Leads', component: Facilities, exact: true, isAuth: true },
  { path: '/leads/dial', name: 'Leads Details', component: DialFacility, isAuth: true },
  { path: '/followups', name: 'Follow Ups', component: FollowUps, isAuth: true },
  { path: '/extensions', name: 'Extensions', component: Extensions, isAuth: true },
  { path: '/users', name: 'Users', component: Users, isAuth: true },
  { path: '/reports', name: 'Reports', component: Reports, isAuth: true },
  { path: '/roles', name: 'Roles', component: Roles, isAuth: true },
  { path: '/groups', name: 'Groups', component: Groups, isAuth: true },
  { path: '/group-configs', name: 'Group', component: GroupConfigs },
  { path: '/agent-configs', name: 'Agent', component: AgentConfigs },
  { path: '/queue-configs', name: 'Queue Configurations', component: QueueConfigs },
  { path: '/service-configs', name: 'Service Configurations', component: ServiceConfigurations },
  { path: '/templates', name: 'Templates', component: Templates },
  { path: '/dialer-reports', name: 'Dialer Reports', component: DialerReports },
  { path: '/disposition-reports', name: 'Disposition Reports', component: DispositionReports },
  { path: '/kpi-analyzer', name: 'KPI Analyzer', component: KPIAnalyzer },
  { path: '/agent-performance', name: 'Agent Performance', component: AgentPerformance },
  { path: '/queue-statistics', name: 'Queue Statistics', component: QueueStatistics },
  { path: '/call-details', name: 'Call Details', component: CallDetails },
  { path: '/agent-activity', name: 'Agent Activity', component: AgentActivity },
  { path: '/agent-etiquettes', name: 'Agent Etiquettes', component: AgentEtiquettes },
  { path: '/agent-monitor', name: 'Agent Activity', component: AgentMonitor },
  { path: '/activity-report', name: 'Agent Activity', component: ActivityReport },
  { path: '/agent-activity', name: 'Agent Activity', component: AgentActivity },
  { path: '/customer-feedback', name: 'Customer Feedback', component: CustomerFeedback },
  { path: '/call-logs', name: 'Call Logs', component: CallLogs },
  { path: '/location-tracker', name: 'Location Tracker', component: LocationTracker },
  { path: '/location-history', name: 'Location History', component: LocationHistory },
  { path: '/message-history', name: 'Message History', component: MessageHistory },
  { path: '/call-stats', name: 'Call Statistics', component: CallStats, exact: true },
  { path: '/call-stats/:dbname', name: 'Call Statistics', component: CallStatsDashboard },
  { path: '/new-dashboard', name: 'Call Statistics', component: CallStatsDashboard },
  { path: '/agent-break-config', name: 'Agent Break Configurations', component: AgentBreakConfig },
  { path: '/field-layout', name: 'Field Layout', component: FieldLayout },
  { path: '/leads/upload', name: 'Upload Leads', component: UploadLeads },
  { path: '/sms-templates', name: 'SMS Templates', component: SMSTemplates },
  { path: '/email-templates', name: 'Email Templates', component: EmailTemplates },
  { path: '/followup-templates', name: 'Follow Up Note Templates', component: FollowUpTemplates },
  { path: '/disposition-templates', name: 'Disposition Note Templates', component: DispositionTemplates },
  { path: '/disposition-notes', name: 'Disposition Notes', component: Disposition },
  { path: '/disposition-classes', name: 'Disposition Classes', component: DispositionClasses },
  { path: '/dispositions', name: 'Dispositions', component: Dispositions },
  { path: '/disposition-plans', name: 'Disposition Plans', component: DispositionPlans },
  { path: '/call-counts', name: 'Call Counts', component: CallCounts },
  { path: '/crm/:module', name: 'CRM', component: Crm, exact: true },
  { path: '/crm-field-layout', name: 'CRM Field Layout', component: CrmFieldSettings, exact: true },
  { path: '/contacts', name: 'Contact', component: ContactView, exact: true },
  { path: '/contacts/:id', name: 'Contact', component: ContactView },
  { path: '/orders', name: 'Sales Orders', component: SalesOrders },
  { path: '/channel-config', name: 'Channels', component: MessengerConfigurations },
  { path: '/inbox', name: 'Inbox', component: Inbox },
  { path: '/dnc', name: 'DNC Management', component: DncManagement },
];

export default routes;
