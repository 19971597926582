import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Col, InputGroup, Input, InputGroupAddon, InputGroupText, Row, FormGroup } from 'reactstrap';

let OtpForm = props => {
    const { handleSubmit, submitting, resendOtp } = props
    const [isLoading, setIsloading] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);
    const [init, setInit] = useState(0);
    const [otp, setOtp] = useState("");

    const OTP_RESEND_TIME = 60;

    useEffect(() => {
        const interval = setInterval(() => {
            setResendTimer(prevTimer => {
                if (prevTimer < OTP_RESEND_TIME) {
                    //console.log("timer");
                    return prevTimer + 1;
                } else {
                    clearInterval(interval);
                    return prevTimer;
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [init]);

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                setIsloading(false);
            }, 5000);
        }
    }, [isLoading]);

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit({ otp });
        }}>
            <h1>Verify OTP</h1>
            <p className="text-muted">The OTP is sent to your mobile</p>
            <FormGroup className="my-3 mt-5">
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i style={{ color: "#000" }} className={"fa fa-unlock-alt"}></i>
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input type='password' autoComplete="new-password" placeholder='Enter the OTP' value={otp} onChange={(e) => setOtp(e.target.value)} />
                </InputGroup>
            </FormGroup>
            <Row>
                <Col xs="4">
                    <Button onClick={() => setIsloading(true)} disabled={submitting} type="submit">{isLoading ? <i className='fa fa-spin fa-circle-o-notch'></i> : ""} Confirm</Button>
                </Col>
                <Col className='text-right' xs="8">
                    {
                        resendTimer < OTP_RESEND_TIME ? <div className='text-muted pt-2'>Resend OTP in {OTP_RESEND_TIME - resendTimer} Seconds</div> : <div className='text-white pt-2 cursor' onClick={() => { setInit(init + 1); setResendTimer(0); resendOtp() }}>Resend OTP</div>
                    }
                </Col>
            </Row>
        </form>
    );
}


export default OtpForm;