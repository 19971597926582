import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Container } from 'reactstrap';
import { connect } from 'react-redux';

import CreateUser from './create';
import ProfilePicture from './ProfilePicture';

class ProfileSettings extends Component {
    constructor(props) {
        super(props);
        this.toggleModel = this.toggleModel.bind(this);
    }

    toggleModel() {
        this.props.toggle();
    }

    render() {
        //console.log();
        const profile = { ...this.props.user, ...this.props.user.user_details };
        return (
            <Modal size="md" isOpen={this.props.isOpen} toggle={this.toggleModel} className={this.props.className}>
                <ModalHeader toggle={this.toggleModel}>Profile Settings</ModalHeader>
                <ModalBody style={{ maxHeight: 500, overflow: "auto" }}>
                    <Container>
                        <Row>
                            <Col><CreateUser own={true} user={profile} onClose={this.toggleModel} /></Col>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>
        );
    }
}

function mapStateToProps({ user }) {
    return {
        user
    };
}
export default (connect(mapStateToProps, null)(ProfileSettings));
