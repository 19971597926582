import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Nav, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import { getAgentMonitorData } from '../actions/reports';
import { getOutboundIDList } from '../actions/users';

import UserMenu from './menus/UserMenu';
import { loadFollowUps } from '../actions/facilities';
import { checkAccessiability } from '../config/util';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      asideContent: "",
      showBreakPanel: false,
      followupAccess: false
    }
  }

  componentWillMount() {
    this.setState({ followupAccess: checkAccessiability('Followups Management', 'READ') });
    this.props.getOutboundIDList();
  }

  componentDidMount() {
    if (this.state.followupAccess) {
      this.props.loadFollowUps();
    }
  }

  followupSidebarTogglerClick(e) {
    this.toggleAsideMenu("followup");
  }

  toggleAsideMenu(content) {
    const asideOpened = document.body.classList.contains('aside-menu-lg-show');
    // this.props.onAsideMenuClicked(content);
    this.setState({ asideContent: content });

    if (this.state.asideContent === content && asideOpened) {
      document.body.classList.remove('aside-menu-lg-show');
    } else {
      document.body.classList.add('aside-menu-lg-show');
    }
  }

  render() {
    const { followups } = this.props;
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <AppNavbarBrand>HSBC Location Tracker</AppNavbarBrand>

        <Nav className="ml-auto px-3" navbar>
          {this.state.followupAccess ?
            <Button onClick={this.followupSidebarTogglerClick.bind(this)} className="d-md-down-none remiderButton" ><i className="fa fa-bell" ></i><span className="count">{followups.length}</span></Button> : ""}
          <UserMenu />
        </Nav>
      </React.Fragment>
    );
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;


function mapStateToProps({ agent_monitor_data, followups }) {
  return {
    agent_monitor_data,
    followups
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadFollowUps,
    getAgentMonitorData,
    getOutboundIDList
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
