import { EXTENTION_LOADED, EXTENTION_DELETED } from '../actions/config';

export default function (state = null, action) {
    switch (action.type) {
        case EXTENTION_LOADED:
            return action.payload;
        case EXTENTION_DELETED:            
            return action.payload;
        default:
            return state;
    }
}
