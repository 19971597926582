import sales_orders from "./reducers/orders";
import pickup_locations from "./reducers/pickup_locations";
import delivery_cities from "./reducers/cities";
import order_config from "./reducers/config";
import skus from "./reducers/skus";

export default {
    sales_orders,
    pickup_locations,
    delivery_cities,
    order_config,
    skus
}