
import React, { Component } from 'react';
import { Row, Col, Button, FormGroup, Input } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { index, send } from '../action';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { formatDateTime, removeNonAscii } from '../../../config/util';

class AddDisposionNote extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: "",
            template: "",
            messageDate: "",
            cursorPosition: 0
        };
    }

    componentDidMount() {
        this.props.listTemplates();
    }

    handleReminderDateChange(date) {
        this.setState({
            due_on: date
        });
    }

    save() {
        const self = this;
        this.props.send({
            "customer_id": this.props.customer.id,
            "desposition": removeNonAscii(this.state.message),
            "disposition_id": this.state.template.id,
            "customer_number": this.props.customer.number
        }, () => {
            if (self.props.onSuccess) {
                self.props.onSuccess();
            }
        });
    }

    selectTemplate(template) {
        this.setState({ "message": template.note_description, template });
    }

    addDateToMessage() {
        const { message, cursorPosition } = this.state;
        this.setState({ "message": [message.slice(0, cursorPosition).trim(), formatDateTime(this.state.messageDate), message.slice(cursorPosition).trim()].join(' ').trim() });
    }

    render() {
        const { disposition_templates } = this.props;

        return (
            <div>
                <Row>
                    <Col>
                        <FormGroup>
                            <label>Message</label>
                            {disposition_templates &&
                                <Select
                                    name="form-field-name2"
                                    value={this.state.template}
                                    options={disposition_templates}
                                    onChange={(e) => this.selectTemplate(e)}
                                    placeholder="Choose a Template"
                                    className="mb-2"
                                    getOptionValue={option => option['note_data']}
                                    getOptionLabel={option => option['note_description']}
                                />}
                            <Input placeholder="Note" type="textarea" value={this.state.message}
                                onChange={(e) => this.setState({ message: e.target.value, cursorPosition: e.target.selectionStart })}
                                onKeyUp={(e) => this.setState({ cursorPosition: e.target.selectionStart })}
                                onClick={(e) => this.setState({ cursorPosition: e.target.selectionStart })} 
                                rows="5" />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <div className="d-flex">
                            <div>
                                <DatePicker
                                    selected={this.state.messageDate}
                                    onChange={(date) => this.setState({ messageDate: date })}
                                    showTimeSelect
                                    customInput={<Button><i className="fa fa-calendar"></i></Button>}
                                />
                            </div>
                            {this.state.messageDate ?

                                <Button className="ml-1" outline onClick={() => this.addDateToMessage()}><i className="fa fa-paste"></i> {formatDateTime(this.state.messageDate)}</Button> : ""}
                            <Button className="ml-auto" disabled={!this.state.message.trim()} onClick={() => this.save()} color="primary">Add Note</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        send,
        listTemplates: index
    }, dispatch);
}

function mapStateToProps({ disposition_templates }) {
    return {
        disposition_templates
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(AddDisposionNote);
