export const SALES_ORDERS_LOADED = "SALES_ORDERS_LOADED";
export const PICKUP_LOCATIONS_LOADED = "PICKUP_LOCATIONS_LOADED";
export const DELIVERY_CITIES_LOADED = "DELIVERY_CITIES_LOADED";
export const CONNECTCO_CONFIG_LOADED = "CONNECTCO_CONFIG_LOADED";
export const SKUS_LOADED = "SKUS_LOADED";

export const ORDER_STATUS = {
    0: { label: 'pending', color: "yellow" },
    1: { label: 'accepted', color: "light_green" },
    2: { label: 'rejected', color: "light_red" },
    3: { label: 'collection_scheduled', color: "orange" },
    4: { label: 'collected', color: "green" },
    5: { label: 'out_for_delivery', color: "light_green" },
    6: { label: 'delivered', color: "green" },
    7: { label: 'delivery_failed', color: "red" },
    8: { label: 'customer_refused', color: "red" },
    9: { label: 'to_be_returned', color: "orange" },
    10: { label: 'returned', color: "light_green" },
    11: { label: 'cancelled', color: "orange" },
    12: { label: 'in_transit', color: "brown" },
    13: { label: 'return_scheduled', color: "brown" },
    14: { label: 'return_in_transit', color: "brown" }
}

export const ORDER_COLUMNS = ["id",
    "order_reference",
    "merchant_id",
    "order_status",
    "order_status_date",
    "hold",
    "pickup_location",
    "pickup_lat",
    "pickup_lng",
    "customer_name",
    "customer_email",
    "delivery_location",
    "delivery_lat",
    "delivery_lng",
    "nearest_delivery_location_id",
    "contact_1",
    "contact_2",
    "location_url",
    "payment_type",
    "delivery_cost",
    "amount_to_be_collected",
    "number_of_packages",
    "package_weight",
    "package_size",
    "delivery_type",
    "scheduled_date",
    "scheduled_tw",
    "scheduled_time_from",
    "scheduled_time_to",
    "notes",
    "invoiced",
    "delivered_at",
    "created_at",
    "updated_at",
    "zone"];