import { AGENT_PHONE_STATUS } from '../actions/config';
import _ from 'lodash';

export default function (state = null, action) {
    switch (action.type) {
        case AGENT_PHONE_STATUS:
            return action.payload;
        default:
            return state;
    }
}
