import _ from "lodash";
import { LINK_3CX_CONFIG, LINK_3CX_REPORTS, LINK_CRM_CONFIG, LINK_CRM_REPORTS } from "./globals";
import Cookies from 'js-cookie';

const getItems = () => {
  let items = [];
  const userData = JSON.parse(Cookies.get("lbcc_user"));

  const checkPermission = (name) => {
    const perm = _.find(userData.login_rules, { module_name: name });
    if (perm) {
      if (perm.readaccess || perm.writeaccess) {
        return true;
      }
    }
    return false;
  }

  if (userData) {
    if (userData.login_rules.length > 0) {
      if (checkPermission("Dashboard")) {
        items.push({
          name: "Dashboards",
          icon: "fa fa-tachometer",
          children: [
            {
              name: "Call Distribution",
              url: "/dashboard",
              icon: "fa fa-line-chart",
            }, {
              name: "Call Statistics",
              url: "/call-stats",
              icon: "fa fa-pie-chart"
            }
          ]
        });
      }

      if (checkPermission("Campaigns Management")) {
        items.push({
          name: "Campaigns",
          icon: "fa fa-columns",
          url: "/campaigns"
        });
      }

      if (checkPermission("Unified Messaging")) {
        items.push({
          name: "Unified Messaging",
          url: "/inbox",
          icon: "fa fa-inbox",
        });
      }



      //--- need to check permissios ---
      if (checkPermission("Contacts Management") || checkPermission("Help Desk") || checkPermission("Sales Order Management")) {
        const crm_items = [];

        if (checkPermission("Contacts Management")) {
          crm_items.push({
            name: "Contacts",
            icon: "fa fa-users",
            url: "/crm/Contacts"
          });
        }

        if (checkPermission("Help Desk")) {
          crm_items.push({
            name: "Tickets",
            icon: "fa fa-ticket",
            url: "/crm/HelpDesk"
          });
        }


        if (checkPermission("Help Desk")) {
          crm_items.push({
            name: "Comments",
            icon: "fa fa-comment",
            url: "/crm/ModComments"
          });
        }

        if (checkPermission("Sales Order Management")) {
          crm_items.push({
            name: "Sales Orders",
            icon: "fa fa-shopping-cart",
            url: "/orders"
          });
        }

        items.push({
          name: "CRM",
          icon: "fa fa fa-id-card-o",
          children: crm_items
        });
      }
      //--- end permission check ---

      const leads = {
        name: "Leads",
        icon: "fa fa-phone-square",
        children: []
      };

      if (checkPermission("Facility Management")) {
        leads.children.push({
          name: "Lead Details",
          url: "/leads",
          icon: "fa fa-address-card-o",
        });
      }


      // if (checkPermission("Followups Management")) {
      //   leads.children.push({
      //     name: "Follow Ups",
      //     url: "/followups",
      //     icon: "fa fa-bell",
      //   });
      // }

      if (checkPermission("Disposition Management", "readaccess")) {
        leads.children.push({
          name: "Disposition Notes",
          url: "/disposition-notes",
          icon: "fa fa-phone-square",
        })
      }

      if (checkPermission("DNC Management")) {
        leads.children.push({
          name: "DNC Management",
          url: "/dnc",
          icon: "fa fa-ban",
        })
      }

      if (leads.children.length > 0) {
        items.push(leads);
      }

      if (checkPermission("Location Management")) {
        items.push({
          name: "Location Tracking",
          icon: "fa fa-map",
          children: [
            {
              name: "Location Tracker",
              url: "/location-tracker",
              icon: "fa fa-map-marker",
            }, {
              name: "Location List",
              url: "/location-history",
              icon: "fa fa-compass",
            }, {
              name: "Message History",
              url: "/message-history",
              icon: "fa fa-comments-o",
            }]
        });
      }

      if (checkPermission("Configurations")) {
        const conf = {
          name: "Configurations",
          icon: "fa fa-cog",
          children: [
            {
              name: "Templates",
              icon: "fa fa-file-text-o",
              children: [
                {
                  name: "Campaigns",
                  url: "/templates",
                  icon: "fa fa-columns",
                },
                {
                  name: "SMS",
                  url: "/sms-templates",
                  icon: "fa fa-comment-o",
                },
                {
                  name: "Email",
                  url: "/email-templates",
                  icon: "fa fa-envelope-o",
                },
                // {
                //   name: "Follow Up Note",
                //   url: "/followup-templates",
                //   icon: "fa fa-bell-o",
                // },
              ],
            },
            {
              name: "Queue Configurations",
              url: "/queue-configs",
              icon: "fa fa-volume-control-phone",
            },
            {
              name: "Service Configurations",
              url: "/service-configs",
              icon: "fa fa-wrench",
            },
            {
              name: "Channels",
              url: "/channel-config",
              icon: "fa fa-plug",
            }
          ],
        };

        if (checkPermission("Campaigns Management")) {
          conf.children.push({
            name: "Agent",
            url: "/agent-configs",
            icon: "fa fa-user",
          });
          conf.children.push({
            name: "Campaign Field Layout",
            url: "/field-layout",
            icon: "fa fa-address-card-o",
          });
        }

        if (checkPermission("Reports")) {
          conf.children.push({
            name: "Agent Breaks",
            url: "/agent-break-config",
            icon: "fa fa-clock-o",
          });
        }

        if (checkPermission("Disposition Management")) {
          conf.children.push({
            name: "Disposition",
            icon: "fa fa-phone-square",
            children: [{
              name: "Disposition Templates",
              url: "/disposition-templates",
              icon: "fa fa-sticky-note-o",
            }, {
              name: "Disposition Classes",
              url: "/disposition-classes",
              icon: "fa fa-object-group",
            }, {
              name: "Dispositions",
              url: "/dispositions",
              icon: "fa fa-comment-o",
            }, {
              name: "Disposition Plans",
              url: "/disposition-plans",
              icon: "fa fa-map-o",
            },
            {
              name: "Follow Ups",
              url: "/followups",
              icon: "fa fa-bell",
            }]
          });
        }

        if (checkPermission("Help Desk") || checkPermission("Contacts Management") || checkPermission("Sales Order Management")) {
          conf.children.push({
            name: "CRM Field Layout",
            url: "/crm-field-layout",
            icon: "fa fa-ticket",
          });
        }

        conf.children.push({
          name: "3CX Configurations",
          url: LINK_3CX_CONFIG,
          attributes: { target: "_blank", external_link: true },
          icon: "fa fa-external-link",
        });

        conf.children.push({
          name: "CRM Configurations",
          url: LINK_CRM_CONFIG,
          attributes: { target: "_blank", external_link: true },
          icon: "fa fa-ticket",
        });

        items.push(conf);
      }

      if (checkPermission("Reports")) {
        const reports = {
          name: "Reports",
          icon: "fa fa-line-chart",
          children: [
            {
              name: "3CX Standard Reports",
              url: LINK_3CX_REPORTS,
              attributes: { target: "_blank", external_link: true },
              icon: "fa fa-external-link",
            }
          ],
        }

        const customReports = {
          name: "Custom Reports",
          icon: "fa fa-table",
          children: [{
            name: "Performance Dashboard",
            url: "/agent-performance",
            icon: "fa fa-users",
            icon: "fa fa-pie-chart",
          }, {
            name: "Etiquettes Rating",
            url: "/agent-etiquettes",
            icon: "fa fa-star-half-o",
          }, {
            name: "Activity Monitor",
            url: "/agent-monitor",
            icon: "fa fa-bell-o",
          }, {
            name: "Activity Reports",
            url: "/activity-report",
            icon: "fa fa-calendar",
          }, {
            name: "Activity Summary",
            url: "/agent-activity",
            icon: "fa fa-clock-o",
          }, {
            name: "Queue Statistics",
            url: "/queue-statistics",
            icon: "fa fa-volume-control-phone",
          }, {
            name: "Call Details",
            url: "/call-details",
            icon: "fa fa-line-chart",
          }, {
            name: "Customer Feedback",
            url: "/customer-feedback",
            icon: "fa fa-comments",
          },
          {
            name: "Outbound Call Counts",
            url: "/call-counts",
            icon: "fa fa-bar-chart",
          },]
        };

        if (checkPermission("Campaigns Management")) {
          customReports.children.push({
            name: "Dialer Reports",
            url: "/dialer-reports",
            icon: "fa fa-phone",
          });
        }

        if (checkPermission("Disposition Management")) {
          customReports.children.push({
            name: "Disposition Reports",
            url: "/disposition-reports",
            icon: "fa fa-phone-square",
          });
        }

        if (checkPermission("Help Desk") || checkPermission("Contacts Management") || checkPermission("Sales Order Management")) {
          reports.children.push({
            name: "CRM Reports",
            url: LINK_CRM_REPORTS,
            attributes: { target: "_blank", external_link: true },
            icon: "fa fa-ticket",
          });
        }

        reports.children.push(customReports);
        items.push(reports);
      }

      if (checkPermission("User Management")) {
        items.push({
          name: "User Management",
          icon: "fa fa-user",
          children: [
            {
              name: "Users",
              url: "/users",
              icon: "fa fa-user",
            },
            {
              name: "Roles",
              url: "/roles",
              icon: "fa fa-key",
            },
            // {
            //   name: "Groups",
            //   url: "/groups",
            //   icon: "fa fa-group",
            // },
          ],
        });
      }

      if (checkPermission("Call Logs Management")) {
        items.push({
          name: "Call Logs",
          url: "/call-logs",
          icon: "fa fa-file-text-o",
        });
      }
    }
  }

  return { items };
}


export default { getItems };
