import { checkAccessiability } from '../../../config/util';
import { DISPOSITION_NOTES_LOADED } from '../config';
import _ from 'lodash';

export default function (state = [], action) {
    switch (action.type) {
        case DISPOSITION_NOTES_LOADED:
            if (checkAccessiability('Disposition Management', 'READ')) {
                return action.payload;
            } else {
                return _.filter(action.payload, { agent_extension: action.extension });
            }
        default:
            return state;
    }
}
