import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import history from '../history';
import Home from './Home';
import Login from './Login';
import { LEMD } from '../config/globals';
import { fetchCsrfToken } from '../actions';
import { bindActionCreators } from 'redux';

class App extends Component {
  componentDidMount() {
    this.props.fetchCsrfToken();
  }

  render() {
    const { user } = this.props;
    console.log(LEMD, Date.now());
    return (
      <div>
        {LEMD < Date.now() ? <div className='bg-danger py-1 text-center'>{atob("U29mdHdhcmUgTGljZW5zZSBFeHBpcmVk")}</div> : ""}
        <ReduxToastr />
        <Router history={history}>
          <Switch>
            {
              user ? <Route path="/" name="Home" component={Home} /> : <Route path="/" name="Login Page" component={Login} />
            }
          </Switch>
        </Router>
      </div>

    );
  }
}

function mapStateToProps({ user }) {
  return {
    user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCsrfToken
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
