import { USERS_LOADED, USER_DELETED } from '../actions/config';

export default function (state = null, action) {
    switch (action.type) {
        case USERS_LOADED:
            return action.payload;
        default:
            return state;
    }
}
