//auth
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const TOKEN_REFRESHED = "TOKEN_REFRESHED";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_STATUS_CHANGED = "USER_STATUS_CHANGED";
export const USER_CONFIG_CHANGED = "USER_CONFIG_CHANGED";
export const AGENT_PHONE_STATUS = "AGENT_PHONE_STATUS";
export const DELETE_GROUP = "DELETE_GROUP";
export const ROLES_LOADED = "ROLES_LOADED";
export const DELETE_ROLE = "DELETE_ROLE";
export const USER_IMAGE_LOADED = "USER_IMAGE_LOADED";
export const PERMISSION_LOADED = "PERMISSION_LOADED";
export const USER_CONFIG_LOADED = "USER_CONFIG_LOADED";
export const GROUP_CONFIG_LOADED = "GROUP_CONFIG_LOADED";
export const QUEUE_CONFIG_CHANGED = "QUEUE_CONFIG_CHANGED";
export const CSRF_TOKEN_UPDATED = "CSRF_TOKEN_UPDATED";

//metadata
export const BRANCHES_LOADED = "BRANCHES_LOADED";
export const PERIOD_DETAILS_LOADED = "PERIOD_DETAILS_LOADED";
export const PRODUCT_DETAILS_LOADED = "PRODUCT_DETAILS_LOADED";
export const STATUS_DETAILS_LOADED = "STATUS_DETAILS_LOADED";
export const LEAD_STATUS_LOADED = "LEAD_STATUS_LOADED";

//users
export const USERS_LOADED = "USERS_LOADED";
export const USER_DELETED = "USER_DELETED";
export const PROFILE_UPDATED = "PROFILE_UPDATED";
export const RING_MY_MOBILE_CHANGED = "RING_MY_MOBILE_CHANGED";
export const OUTBOUND_IDS_LOADED = "OUTBOUND_IDS_LOADED";
export const OUTBOUND_ID_CHANGED = "OUTBOUND_ID_CHANGED";

//groups
export const GROUPS_LOADED = "GROUPS_LOADED";
export const GROUP_DELETED = "GROUP_DELETED";
export const GROUP_CONFIG_CHANGED = "GROUP_CONFIG_CHANGED";

//facilities
export const FACILITY_LOADED = "FACILITY_LOADED";
export const FACILITIES_LOADED = "FACILITIES_LOADED";
export const FACILITY_DETAILS_LOADED = "FACILITY_DETAILS_LOADED";
export const CUSTOMER_LOADED = "CUSTOMER_LOADED";
export const NEXT_CUSTOMER_LOADED = "NEXT_CUSTOMER_LOADED";


//followups
export const FOLLOWUPS_SAVED = "FOLLOWUPS_SAVED";
export const FOLLOWUPS_LOADED = "FOLLOWUPS_LOADED";
export const FOLLOWUP_METADATA_LOADED = "FOLLOWUP_METADATA_LOADED";

//campaigns
export const CAMPAIGNS_LOADED = "CAMPAIGNS_LOADED";
export const TEMPLATES_LOADED = "TEMPLATES_LOADED";
export const CAMPAIGNS_RESYNCED = "CAMPAIGNS_RESYNCED";

//extentions
export const EXTENTION_LOADED = "EXTENTION_LOADED";
export const EXTENTION_DELETED = "EXTENTION_DELETED";
export const KPIPARAMS_LOADED = "KPIPARAMS_LOADED";
export const QUEUES_LOADED = "QUEUES_LOADED";
export const IVRS_LOADED = "IVRS_LOADED";
export const AGENT_GROUPS_LOADED = "AGENT_GROUPS_LOADED";
export const AGENT_BREAK_CONFIG_LOADED = "AGENT_BREAK_CONFIG_LOADED";
export const GROUPS_3CX_LOADED = "GROUPS_3CX_LOADED";

//reports
export const REPORT_KPI_LOADED = "REPORT_KPI_LOADED";
export const CALL_COUNTS_LOADED = "CALL_COUNTS_LOADED";
export const AGENT_PERFORMANCE_LOADED = "AGENT_PERFORMANCE_LOADED";
export const REPORTS_LOADED = "REPORTS_LOADED";
export const AGENT_ACTIVITY_TMPL_LOADED = "AGENT_ACTIVITY_TMPL_LOADED";
export const AGENT_ACTIVITIES_LOADED = "AGENT_ACTIVITIES_LOADED";
export const AGENT_ACTIVITIES_SELECTED = "AGENT_ACTIVITIES_SELECTED";
export const AGENTS_LOADED = "AGENTS_LOADED";
export const AGENT_ACTIVITY_REPORT_LOADED = "AGENT_ACTIVITY_REPORT_LOADED";
export const QUEUE_STATS_LOADED = "QUEUE_STATS_LOADED";
export const CALL_DETAILS_LOADED = "CALL_DETAILS_LOADED"
export const AGENT_ETIQUETTE_REPORT_LOADED = "AGENT_ETIQUETTE_REPORT_LOADED";
export const AGENT_MONITOR_LOADED = "AGENT_MONITOR_LOADED";
export const AGENT_ACTIVITY_LOGS_LOADED = "AGENT_ACTIVITY_LOGS_LOADED";
export const AGENT_STATUS_CHANGE_REQUESTS_LOADED = "AGENT_STATUS_CHANGE_REQUESTS_LOADED";
export const CALL_LOGS_LOADED = "CALL_LOGS_LOADED";
export const AGENT_CALL_COUNTS_LOADED = "AGENT_CALL_COUNTS_LOADED";
export const DISPOSITION_REPORT_LOADED = "DISPOSITION_REPORT_LOADED";

//feedback
export const FEEDBACK_TRANSFER_EXT_LOADED = "FEEDBACK_TRANSFER_EXT_LOADED";
export const FEEDBACK_HOTLINE_LOADED = "FEEDBACK_HOTLINE_LOADED";
export const FEEDBACK_LANGUAGES_LOADED = "FEEDBACK_LANGUAGES_LOADED";
export const FEEDBACK_LIST_LOADED = "FEEDBACK_LIST_LOADED";
export const FEEDBACKS_LOADED = "FEEDBACKS_LOADED";

export const searchTypes = { LEAD_ID: "Lead ID", PHONE_NUMBER: "Phone Number" };
