import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import Cookies from 'js-cookie';
import { getStatus, logout, setStatus, getAgentPhoneStatus } from '../../actions/index';
import ProfileSettings from '../views/Users/ProfileSettings';
import history from '../../history';
import { loadAgentActivities } from '../../actions/reports';
import { AGENT_STATUS_REFRESH, PHONE_STATUS_REFRESH } from '../../config/globals';

class UserMenu extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
            dropdown2Open: false,
            modal: false,
            loggeduser: ''
        };

        this.toggleModel = this.toggleModel.bind(this);
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    toggle2() {
        this.setState({
            dropdown2Open: !this.state.dropdown2Open,
        });
    }

    toggleModel() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    componentWillMount() {
        this.props.loadAgentActivities();
        if (JSON.parse(Cookies.get('lbcc_user'))) {
            this.setState({
                loggeduser: JSON.parse(Cookies.get('lbcc_user')).login_username
            });
        }

        this.props.getStatus();
        this.props.getAgentPhoneStatus();

        this.refreshIntervalPhone = window.setInterval(() => {
            this.props.getAgentPhoneStatus();
        }, PHONE_STATUS_REFRESH * 1000);

        this.refreshIntervalAgentStatus = window.setInterval(() => {
            this.props.getStatus();
        }, AGENT_STATUS_REFRESH * 1000)
    }

    componentWillUnmount() {
        window.clearInterval(this.refreshIntervalPhone);
        window.clearInterval(this.refreshIntervalAgentStatus);
    }

    logout() {
        this.props.logout(() => {
            history.push("/");
        })
    }

    render() {
        const { user, agent_activities, user_status } = this.props;
        return (
            <div className='d-flex' style={{ height: 21, marginLeft: 10 }}>
                {user.user_details.ringmymobile ? <span title='Mobile forward enabled' className={`user-status bg-primary text-center mr-1`}><i className={`fa fa-mobile`}></i></span> : ""}
                {user_status ? user_status.qstatus ? <span title='Q loggedIn' className={`user-status bg-primary text-center mr-1`}><i className={`fa fa-sign-in`}></i></span> : "" : ""}
                <Dropdown nav className="d-md-down-none" isOpen={this.state.dropdownOpen} toggle={() => this.toggle()}>
                    <DropdownToggle nav>
                        {this.state.loggeduser ? (<span ><span title={user_status ? user_status.status_name : "Checking the status"} className={`user-status bg-${user_status && agent_activities && agent_activities.length > 0 ? _.find(agent_activities, { id: user_status.status_id }).color_desc : "info"} `}><i className={`fa fa-user`}></i></span> {user.user_details.first_name + ' ' + user.user_details.last_name}</span>) : (<span className="text-avatar bg-info">A</span>)}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-lg" right>
                        <DropdownItem onClick={() => { this.setState({ modal: true }) }}><i className="fa fa-user"></i>Profile Settings</DropdownItem>
                        <DropdownItem onClick={() => this.logout()}><i className="fa fa-sign-out"></i> Logout</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <ProfileSettings isOpen={this.state.modal} toggle={this.toggleModel} />
            </div>

        );
    }
}

function mapStateToProps({ user, agent_activities, phone_status, user_status }) {
    return {
        user,
        agent_activities,
        phone_status,
        user_status
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout,
        loadAgentActivities,
        getAgentPhoneStatus,
        setStatus,
        getStatus
    }, dispatch);
}


export default (connect(mapStateToProps, mapDispatchToProps)(UserMenu));
