import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import { API_URL } from '../config/globals';
import store from '../index';
import history from '../history';
import Cookies from 'js-cookie';
import { USER_LOGGED_IN, USER_LOGGED_OUT, TOKEN_REFRESHED, CSRF_TOKEN_UPDATED } from './config';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Content-Type'] = "application/json";

axios.interceptors.request.use(
    config => {
        config.withCredentials = true;
        config.headers['CSRF-Token'] = store.getState().csrf_token;

        if (config.headers['Authorization']) {
            return config;
        } else {
            const token = Cookies.get("lbcc_user");
            if (token) {
                const at = JSON.parse(token);
                config.headers['Authorization'] = `Bearer ${at.access_token}`;
                return config;
            } else {
                return config;
            }
        }
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(function (response) {
    if (response.data && (response.data.status === "error" || response.data.status === "failed")) {
        toastr.error(response.data.status.toUpperCase(), response.data.message)
        return { data: null };
    } else if (response.data && response.data.status === "warning") {
        toastr.warning(response.data.status.toUpperCase(), response.data.message);
        return { data: null };
    } else {
        return response;
    }
}, function (error) {
    if (!error.response) {
        store.dispatch({
            type: USER_LOGGED_OUT,
            payload: null
        });

        if (history.location.pathname !== "/") {
            history.push("/");
        }
        return Promise.reject(error);
    } else {
        if (error.response.data.message === "jwt expired") {
            return new Promise((resolve, reject) => {
                store.dispatch(refreshToken((token) => {
                    error.config.headers.Authorization = `Bearer ${token}`;
                    axios(error.config).then((r) => resolve(r)).catch((e) => reject(e));
                }));
            })
        } else {
            return Promise.reject(error.response.data);
        }
    }
});

export const fetchCsrfToken = () => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/csrf-token'
        }).then(function (results) {
            // Cookies.set('csrf-token', results.data.csrfToken, { secure: true, sameSite: 'none' });
            dispatch({
                type: CSRF_TOKEN_UPDATED,
                payload: results.data.csrfToken
            })
        }).catch(function (error) {
        });
    }
};

export const login = (auth, next) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/oauth/token',
            params: {
                grant_type: "client_credentials",
            },
            auth: auth
        }).then(function (response) {
            if (response.data) {
                Cookies.set('username', auth.username, { secure: true, sameSite: 'none' });
                if (response.data.access_token) {
                    axios({
                        method: 'get',
                        url: API_URL + '/AccessManager/Login',
                        params: {
                            user: auth.username
                        },
                        headers: {
                            Authorization: `Bearer ${response.data.access_token}`
                        }
                    }).then(function (userData) {
                        dispatch({
                            type: USER_LOGGED_IN,
                            payload: { ...response.data, ...userData.data }
                        });
                    }).catch(function (error) {
                        toastr.error('Error', error.message);
                    });
                } else {
                    next(true);
                }
            }
        }).catch(function (error) {
            //console.log(error);
            toastr.error('Error', "Invalid Credentials");
        });
    }
}

export const verifyOtp = (data, next) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/oauth/verify',
            data: {
                username: Cookies.get('username'),
                otp: data.otp
            }
        }).then(function (response) {
            if (response.data) {

                axios({
                    method: 'get',
                    url: API_URL + '/AccessManager/Login',
                    params: {
                        user: data.username
                    },
                    headers: {
                        Authorization: `Bearer ${response.data.access_token}`
                    }
                }).then(function (userData) {
                    dispatch({
                        type: USER_LOGGED_IN,
                        payload: { ...response.data, ...userData.data }
                    });
                }).catch(function (error) {
                    toastr.error('Error', error.message);
                });
            }
        }).catch(function (error) {
            //console.log(error);
            if (error.message) {
                toastr.error('Error', error.message);
            } else {
                toastr.error('Error', "Could not verify the OTP");
            }
        });
    }
}

export const resendOtp = () => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/oauth/resendOtp',
            data: {
                username: Cookies.get('username')
            }
        }).then(function (response) {
            toastr.success('Success', response.data.message);
        }).catch(function (error) {
            if (error.message) {
                toastr.error('Error', error.message);
            } else {
                toastr.error('Error', "Could not verify the OTP");
            }
        });
    }
}

export const refreshToken = (next) => {
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_URL + '/oauth/refresh',
            data: {
                token: getState().user.refresh_token
            }
        }).then(function (response) {
            if (response.data) {
                //console.log("token refreshed", response.data);
                dispatch({
                    type: TOKEN_REFRESHED,
                    payload: response.data
                });
                setTimeout(() => {
                    next(response.data.access_token);
                }, 1000)
            }
        }).catch(function (error) {
            dispatch({
                type: USER_LOGGED_OUT,
                payload: null
            });
        });
    }
}

export const logout = (next) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/AccessManager/Logout',
            params: {

                user: getState().user.user_details.extension,
            }
        }).then(function (results) {
            dispatch({
                type: USER_LOGGED_OUT,
                payload: null
            });
            next();
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}


export const setStatus = (status, substatus) => {
    return (dispatch, getState) => {
        axios({
            method: 'get',
            url: API_URL + '/DIALER/SetPresenceStatus',
            params: {

                extension: getState().user.user_details.extension,
                status,
                substatus
            }
        }).then(function (results) {
            dispatch(getStatus())
        }).catch(function (error) {
            toastr.error('Error', error.message);
        });
    }
}


export const getStatus = (next) => {
    return (dispatch, getState) => {
        // axios({
        //     method: 'get',
        //     url: API_URL + '/DIALER/GetPresenceStatus',
        //     params: {
        //     
        //         extension: getState().user.user_details.extension,
        //     }
        // }).then(function (results) {
        //     dispatch({
        //         type: USER_STATUS_CHANGED,
        //         payload: results.data
        //     });
        // }).catch(function (error) {
        //     toastr.error('Error', error.message);
        // });
    }
}

export const getAgentPhoneStatus = () => {
    return (dispatch, getState) => {
        // axios({
        //     method: 'get',
        //     url: API_URL + '/DIALER/GetAgentPhoneStatus',
        //     params: {
        //     
        //         extension: getState().user.user_details.extension
        //     }
        // }).then(function (response) {
        //     // if (response.data) {
        //     //     if (response.data.status === "idle") {
        //     //         dispatch(setStatus(1));
        //     //     } else if (response.data.status === "ringing" || response.data.status === "dialing") {
        //     //         dispatch(setStatus(2));
        //     //     }
        //     // }

        //     dispatch({ type: AGENT_PHONE_STATUS, payload: response.data });
        // }).catch(function (error) {
        //     toastr.error('Error', error.message);
        // });
    }
}