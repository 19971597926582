import moment from "moment";
import { INTL_PHONE_REGEX, LOCAL_PHONE_REGEX } from "./globals";
import Cookies from 'js-cookie';

export const checkAccessiability = (module_name, accetion_type) => {
    var userData = JSON.parse(Cookies.get('lbcc_user'))
    if (userData) {
        if (userData.login_rules.length > 0) {
            // //console.log('in');
            for (var i = 0; i < userData.login_rules.length; i++) {
                if (userData.login_rules[i].module_name === module_name) {
                    //   //console.log('in 2');
                    if (accetion_type === 'READ') {
                        if (userData.login_rules[i].readaccess) {
                            //    //console.log('return true 1');
                            return true;
                        } else {
                            //    //console.log('return false 3');
                            return false;
                        }
                    } else if (accetion_type === 'WRITE') {
                        if (userData.login_rules[i].writeaccess) {
                            //    //console.log('return true 2');
                            return true;
                        } else {
                            //   //console.log('return false 2');
                            return false;
                        }
                    }
                } else {
                    ////console.log('in else',userData.login_rules.length);
                    //  //console.log('i==>',i);
                    if ((i) === (userData.login_rules.length - 1)) {
                        //    //console.log('return false 1');
                        return false;
                    } else {
                        //    //console.log('in else');

                    }
                }


            }
        } else {
            //console.log('return false 4');
            return false;
        }
    } else {
        //console.log('return false 5');
        return false;
    }
}

export const formatCurrency = (number = 0, decimals = 2) => {
    var val = number.toFixed(decimals);
    var parts = val.toString().split(".");
    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
    return num;
}

function pad(num) {
    return ("0" + num).slice(-2);
}

export function formatDuration(secs) {
    var minutes = Math.floor(secs / 60);
    secs = secs % 60;
    var hours = Math.floor(minutes / 60)
    minutes = minutes % 60;
    return pad(hours) + ":" + pad(minutes) + ":" + pad(secs);
}

export function formatFriendlyDuration(text) {
    if (text) {
        var params = text.split(":");
        var str = "";
        if (parseInt(params[0], 10) > 0) {
            str += parseInt(params[0], 10) + " hours "
        }

        if (parseInt(params[1], 10) > 0) {
            str += parseInt(params[1], 10) + "  minutes "
        }

        return str.trim();
    } else {
        return "";
    }
}

export function formatDateTime(time) {
    return moment(time).format("YYYY-MM-DD HH:mm:ss");
}

export function removeMilliseconds(time = "") {
    return time.split(".")[0];
}

export function getReportData(data) {
    if (typeof data === "object" && !Array.isArray(data)) {
        data = [data]
    }

    if (!data || data.length === 0) {
        return { data: null, headers: null };
    }
    const headers = Object.keys(data[0]).map((key) => {
        if (typeof data[0][key] === "object") {
            return undefined;
        } else {
            return { key, label: _.startCase(key) };
        }
    }).filter(a => a);
    return { data, headers };
}

export function formatPhone(data) {
    data = data.replace(/\D/g, '');

    if (LOCAL_PHONE_REGEX.test(data)) {
        return data;
    } else if (INTL_PHONE_REGEX.test(data)) {
        return data;
    } else {
        return null;
    }
}

export function convertToKeyValue(obj) {
    const arr = [];
    for (let k in obj) {
        arr.push({
            label: obj[k],
            value: k,
        })
    }
    return arr;
}

export function removeNonAscii(str) {
    if ((str === null) || (str === ''))
        return false;
    else
        str = str.toString();

    return str.replace(/[^\x20-\x7E]/g, '');
}
