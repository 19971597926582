import { combineReducers } from "redux";
import { reducer as form } from 'redux-form';
import { reducer as toastr } from 'react-redux-toastr';

import user from "./auth";
import metadata from "./metadata";
import users from "./users";
import groups from "./groups";
import facility from "./facility";
import facilities from "./facilities";
import leadstatus from "./leadstatus";
import campaigns from "./campaigns";
import roles from "./roles";
import permissions from "./permissions";
import configurations from "./configurations";
import followups from "./followups";
import agent_configs from "./agent_configs";
import group_configs from "./group_configs";
import queue_configs from "./queue_config";
import templates from "./templates";
import kpi_report from "./kpi_report";
import call_counts from "./call_counts";
import agent_performance from "./agent_performance";
import reports from "./reports";
import images from "./images";
import agent_activity_tmpl from "./agent_activity_tmpl";
import agents from "./agents";
import agent_activities from "./agent_activities";
import agent_activity_report from "./agent_activity_report";
import queue_statistics from "./queue_statistics";
import agent_etiquettes from "./agent_etiquettes";
import feedback_meta from "./feedback_meta";
import feedback_report from "./feedback_report";
import agent_monitor_data from "./agent_monitor_data";
import agent_activity_logs from "./agent_activity_logs";
import agent_status_changes from "./agent_status_changes";
import call_logs from "./call_logs";
import agent_call_counts from "./agent_call_counts";
import queues from "./queues";
import ivrs from "./ivrs";
import agent_groups from "./agent_groups";
import locations from "../modules/location-tracker/reducer";
import user_locations from "../modules/location-tracker/user_locations";
import location_history from "../modules/location-tracker/location_history";
import dashboards from "../modules/dashboards/reducer";
import agent_break_configs from "./agent_break_configs";
import groups_3cx from "./groups_3cx";
import field_layout from "../modules/field-layout/reducer";
import disposition_categories from "../modules/disposition/reducers/categories";
import disposition_notes from "../modules/disposition/reducers/notes";
import phone_status from "./phone-status";
import sms_templates from "../modules/sms-templates/reducer";
import email_templates from "../modules/email-templates/reducer";
import disposition_templates from "../modules/disposition-note-templates/reducer";
import disposition_classes from "../modules/disposition-classes/reducer";
import dispositions from "../modules/dispositions/reducer";
import disposition_plans from "../modules/disposition-plans/reducer";
import followup_templates from "../modules/followup-note-templates/reducer";
import service_configs from "../modules/service-configurations/reducer";
import orders from "../modules/orders/reducer";
import crm from "../modules/crm/reducer.js";
import dnc from "../modules/dnc/reducer";
import user_status from "./user_status";
import call_details from "./call_details";
import outbound_ids from "./outbound_ids";
import dispostion_report from "./dispostion_report";
import csrf_token from "./csrf_token.js";

const rootReducer = combineReducers({
    user,
    user_status,
    metadata,
    form,
    toastr,
    users,
    groups,
    facility,
    facilities,
    leadstatus,
    campaigns,
    roles,
    permissions,
    configurations,
    followups,
    agent_configs,
    group_configs,
    templates,
    kpi_report,
    call_counts,
    agent_performance,
    reports,
    images,
    agent_activity_tmpl,
    agents,
    agent_activities,
    agent_activity_report,
    queue_statistics,
    agent_etiquettes,
    feedback_meta,
    feedback_report,
    agent_monitor_data,
    agent_activity_logs,
    agent_status_changes,
    call_logs,
    queues,
    locations,
    dashboards,
    ivrs,
    agent_groups,
    user_locations,
    agent_break_configs,
    groups_3cx,
    location_history,
    field_layout,
    disposition_categories,
    disposition_notes,
    disposition_plans,
    disposition_classes,
    ...dispositions,
    sms_templates,
    email_templates,
    disposition_templates,
    followup_templates,
    agent_call_counts,
    phone_status,
    queue_configs,
    call_details,
    outbound_ids,
    ...crm,
    ...orders,
    service_configs,
    dnc,
    dispostion_report,
    csrf_token
});

export default rootReducer;
