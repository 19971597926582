export const MODULE_LAYOUT_LOADED = "MODULE_LAYOUT_LOADED";
export const FIELD_LIST_LOADED = "FIELD_LIST_LOADED";
export const GET_ALL_RECORDS = "GET_ALL_RECORDS";
export const RECORD_LOADED = "RECORD_LOADED";

export const searchTypes = {
    CONTACT_ID: {
        label: "Contact ID",
        value: "contact_no"
    },
    PHONE_NUMBER: {
        label: "Phone",
        value: "phone"
    }
};

export const MODULES = {
    "HelpDesk": "Help Desk",
    "Contacts": "Contacts",
    "ModComments": "Comments",
}


export const TICKET_STATUS_COLORS = {
    "Open": "gray",
    "In Progress": "yellow",
    "Wait For Response": "LIGHT_RED",
    "Closed": "green"
}