import { PICKUP_LOCATIONS_LOADED } from '../config';

export default function (state = null, action) {
    switch (action.type) {
        case PICKUP_LOCATIONS_LOADED:
            return action.payload;
        default:
            return state;
    }
}
